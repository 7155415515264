import ModalBase from "components/ModalBase/modal_base";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { Form, Formik } from "formik";
import React from "react";
import MDTypography from "components/MDTypography";
import { useCloseModal } from "hooks/useCloseModal";
import FormField from "components/FormField";
import { useCreateTagsMutation } from "services";

const ModalCarpetaCursos = ({ open, handleClose }) => {
  const [
    crearCarpeta,
    {
      isSuccess: isSuccessC,
      isError: isErrorC,
      isLoading: isLoadingC,
      error: errorC,
    },
  ] = useCreateTagsMutation();

  const submitForm = async (values, actions) => {
    try {
      await crearCarpeta(values);
    } catch (error) {
      console.error(error);
      actions.setSubmitting(true);
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
  };

  useCloseModal(
    isLoadingC,
    isSuccessC,
    isErrorC,
    errorC,
    `Nueva carpeta creada correctamente.`,
    handleClose
  );

  return (
    <ModalBase open={open} handleClose={handleClose}>
      <MDBox>
        <Formik initialValues={{ nombre: "" }} onSubmit={handleSubmit}>
          {({ values }) => {
            return (
              <Form>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <MDTypography variant="h5" fontWeight="medium" mb={1}>
                      Nombre de la carpeta que desea crear:
                    </MDTypography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormField
                      label={"Nombre"}
                      name={"nombre"}
                      value={values.nombre}
                      onInput={(e) =>
                        (e.target.value = ("" + e.target.value).toUpperCase())
                      }
                    />
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <MDBox
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                      mt={3}
                    >
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="medium"
                        onClick={handleClose}
                      >
                        cancelar
                      </MDButton>
                      {/* {isLoadingE && (
                        <Grid item mt={1}>
                          <CircularProgress size={30} color="inherit" />
                        </Grid>
                      )} */}
                      <MDButton
                        variant="gradient"
                        size="medium"
                        type="submit"
                        color="light"
                      >
                        Aceptar
                      </MDButton>
                    </MDBox>
                  </Grid>
                </Grid>
              </Form>
            );
          }}
        </Formik>
      </MDBox>
    </ModalBase>
  );
};

export default ModalCarpetaCursos;
