import { actasApi } from "../view/actas.service";
import { evaluacionesApi } from "../../distribuciones/distribucionDeEvaluaciones.service";
import { asignaturasPorProfesorApi } from "../../distribuciones/distribucionDeAsignaturasPorProfesor.service";

export const deleteActa = actasApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteActa: builder.mutation({
      query: ({ id }) => ({
        url: `/v1/api/acta/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Actas", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              evaluacionesApi.util.invalidateTags([
                { type: "GruposPorEvaluaciones", id: "LIST" },
              ])
            );
            dispatch(
              asignaturasPorProfesorApi.util.invalidateTags([
                { type: "AsignaturasPorProfesor", id: "LIST" },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useDeleteActaMutation } = deleteActa;
