import { tagsApi } from "../view/tagsService";

export const createTags = tagsApi.injectEndpoints({
  endpoints: (builder) => ({
    createTags: builder.mutation({
      query: (data) => {
        return {
          url: "/v1/api/tag/",
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: [{ type: "tags", id: "LIST" }],
    }),
  }),
});

export const { useCreateTagsMutation } = createTags;
