/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DataTable from "examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import Swal from "sweetalert2";
import PropTypes from "prop-types";

import ComponentsPrivatization from "config/component_privatization";
import { style_icon } from "helpers/global_vars";
import {
  CURSOS_EDIT_URL,
  CURSOS_LIST_URL,
  CURSOS_NEW_URL,
  CURSOS_SHOW_URL,
} from "config/bases_url";
import { useRedirectForm } from "hooks/useRedirectForm";
import { CONSTANCIAS, COORDINADOR_DOCENTE, FIRMANTE } from "roles";
import usePagination from "hooks/usePagination";
import { useDeleteCursoMutation, useGetCursosQuery } from "services";
import { useCallback, useState } from "react";
import { toIsoDate } from "../../../../helpers/functions";

import { formatoAEquivalenteHoras } from "helpers/functions";
import { FiltersComponentCarpeta } from "./carpetasFiltros";
import { useGetTagsQuery } from "services";

function DataTableCursos({ tableHeader }) {
  const navigate = useNavigate();

  const { offset, setOffset, limit, handleNext, handlePrevious } =
    usePagination();

  const [carpetasState, setCarpetasState] = useState("");

  const {
    data: cursos,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetCursosQuery(
    {
      filter_params: {
        ordering: "nombre",
        limit: limit,
        offset: offset,
        tags: carpetasState,
        query:
          "{id,url,nombre,duracion, expedicion, firmante{user{apellido_paterno,apellido_materno,first_name}}}",
      },
      shape: {
        id: "id",
        url: "url",
        nombre: "nombre",
        expedicion: "expedicion",
        firmante:
          "firmante.user.apellido_paterno firmante.user.apellido_materno firmante.user.first_name",
        duracion: "duracion",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  const { data: carpetas, isLoading: isLoadingCarp } = useGetTagsQuery();

  //constantes para manejar la paginacion
  const dataCursos = cursos?.results ? cursos.results : cursos;
  const countTotal = cursos?.count;
  const previous = cursos?.previous;
  const next = cursos?.next;

  const [
    deleteCurso,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteCursoMutation();

  const dataTable = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Duración",
        accessor: "duracion",
        Cell: ({ value }) => {
          let formato;
          if (value !== undefined) {
            formato = formatoAEquivalenteHoras(value);
          }

          return formato ?? "-------------";
        },
      },
      {
        Header: "Expedición",
        accessor: "expedicion",
        Cell: ({ value }) => toIsoDate(value),
      },
      {
        Header: "Firmante",
        accessor: "firmante",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization
                permitted={[COORDINADOR_DOCENTE, CONSTANCIAS, FIRMANTE]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Ver toda la información del curso en vista de detalles (Puede acceder a esta funncionalidad con doble click en cualquier parte de la fila)"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickShow(original)}
                      fontSize="small"
                    >
                      find_in_page
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
              <ComponentsPrivatization
                permitted={[COORDINADOR_DOCENTE, CONSTANCIAS]}
              >
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información del curso"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Eliminar el curso con toda su información"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() =>
                        handleClickErase(original.id, original.nombre)
                      }
                      fontSize="small"
                    >
                      remove_circle
                    </Icon>
                  </Tooltip>
                </MDBox>
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: dataCursos ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    CURSOS_LIST_URL
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Curso eliminado correctamente",
    CURSOS_LIST_URL
  );

  const handleClickErase = (id, nombre) => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: `¡Si eliminas "${nombre}", la acción no se podrá revertir!`,
      icon: "warning",
      reverseButtons: true,
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteCurso(id);
      }
    });
  };

  const handleClickEdit = (el) => {
    navigate(CURSOS_EDIT_URL(el.id));
  };

  const handleClickShow = useCallback((el) => {
    navigate(CURSOS_SHOW_URL(el.id));
  }, []);

  const handleChange = (e) => {
    setCarpetasState(e.target.value);
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>

          <ComponentsPrivatization
            permitted={[COORDINADOR_DOCENTE, CONSTANCIAS]}
          >
            <Link to={CURSOS_NEW_URL}>
              <Tooltip
                placement="top"
                title="Crear nuevo curso extracurricular con toda su información requerida"
              >
                <MDButton variant="gradient" color="info" size="medium">
                  nuevo
                </MDButton>
              </Tooltip>
            </Link>
          </ComponentsPrivatization>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG || isFetching || isLoadingCarp ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={dataTable}
              doubleClick={handleClickShow}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
              filtersComponent={
                <FiltersComponentCarpeta
                  carpeta={carpetasState}
                  handleChange={handleChange}
                  carpetas={carpetas}
                />
              }
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableCursos.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableCursos;
