import {
  Document,
  Page,
  Image,
  Text,
  Font,
  StyleSheet,
  View,
  Svg,
  Line,
} from "@react-pdf/renderer";
import {
  changePalabra,
  numb_to_letter,
  paridadNew,
} from "../../../../helpers/functions";
import ArialMT_Bold from "../../../../assets/fonts/arial-mt-cufonfonts/ARIALBOLDMT.OTF";
import ArialMT from "../../../../assets/fonts/arial-mt-cufonfonts/arialmt.ttf";

Font.register({
  family: "ArialMT",
  fonts: [
    { src: ArialMT, fontWeight: "light" },
    { src: ArialMT_Bold, fontWeight: "bold" },
  ],
});

const styles = StyleSheet.create({
  textBold: {
    fontFamily: "ArialMT",
    fontWeight: "bold",
    fontSize: "9pt",
    textTransform: "uppercase",
  },
  textInfoTitle: {
    fontFamily: "ArialMT",
    fontSize: "7pt",
    textTransform: "uppercase",
    lineHeight: "1.8",
    fontWeight: "bold",
  },
  textInfo: {
    fontFamily: "ArialMT",
    fontSize: "7pt",
    textTransform: "uppercase",
    marginLeft: "5px",
    lineHeight: "1.8",
  },
  textFooterRed: {
    fontFamily: "ArialMT",
    fontSize: "7pt",
    textTransform: "uppercase",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#880027",
  },
  texWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "4px",
  },
  infoIntermedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  tableStyle: {
    display: "table",
    marginTop: "5px",
    marginHorizontal: "10px",
  },
  tableRowStyle: {
    flexDirection: "row",
  },
  firstchild: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
  },
  tableColHeaderStyle: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: "thick",
  },
  tableColStyle: {
    borderRightStyle: "solid",
    borderRightColor: "#000",
    borderRightWidth: 1,
    borderBottomStyle: "dashed",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    borderTopWidth: "thick",
  },
  tableCellHeaderStyle: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "7pt",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  textCell: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "7pt",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "normal",
    marginTop: "1px",
  },
});

const ActaAcreditacion = ({ info, cicle_name, notas, imagenes }) => {
  const entidad = JSON.parse(sessionStorage.getItem("user"))?.perfil?.entidad;

  return (
    <Document
      producer="TecnoSchool PDF"
      creator="TecnoSchool"
      author="TecnoSchool"
      title="Acta de acreditacion"
      subject="Acta de acreditacion"
      keywords="acta, acreditacion, escuela"
      language="es-MX"
      pdfVersion="1.7"
    >
      <Page
        size="LETTER"
        style={{
          paddingLeft: "0.19cm",
          paddingRight: "0.19cm",
          paddingTop: "0.1cm",
        }}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            margin: "5px 25px",
            alignItems: "center",
          }}
        >
          <Image
            component="img"
            src={imagenes.header}
            variant="gradient"
            style={{
              height: "1.94cm",
              width: "100%",
            }}
          />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <View style={styles.texWrapper}>
            <Text style={styles.textBold}>
              SISTEMA DE ACREDITACIÓN Y CERTIFICACIÓN NORMAL
            </Text>
            <Text style={styles.textBold}>ACTA DE ACREDITACIÓN</Text>
            <Text style={styles.textBold}>{entidad?.nombre}</Text>
            <Text style={styles.textBold}>
              C.C.T {entidad.clave_del_centro}
            </Text>
            <Text style={styles.textBold}>Departamento de Control Escolar</Text>
            <Text style={styles.textBold}>
              CICLO ESCOLAR: {cicle_name} {paridadNew(info.semestre)}
            </Text>
          </View>
        </View>
        <View
          style={{
            marginTop: "5px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={[styles.infoIntermedia, { marginLeft: "1.2cm" }]}>
            <View>
              <Text style={styles.textInfoTitle}>Licenciatura:</Text>
              <Text style={styles.textInfoTitle}>PROFESOR:</Text>
              <Text style={styles.textInfoTitle}>CURSO:</Text>
              <Text style={styles.textInfoTitle}>CLAVE:</Text>
            </View>
            <View style={{ marginTop: "1.5px" }}>
              <Text style={styles.textInfo}>{info.carrera}</Text>
              <Text style={styles.textInfo}>{info.profesor_name}</Text>
              <Text style={styles.textInfo}>{info.asignatura}</Text>
              <Text style={styles.textInfo}>{info.asignatura_clave}</Text>
            </View>
          </View>
          <View style={[styles.infoIntermedia, { marginRight: "2cm" }]}>
            <View>
              <Text style={styles.textInfoTitle}>SEMESTRE:</Text>
              <Text style={styles.textInfoTitle}>GRUPO:</Text>
              <Text style={styles.textInfoTitle}>MODALIDAD:</Text>
              <Text style={styles.textInfoTitle}>TURNO:</Text>
            </View>
            <View style={{ marginTop: "1.5px" }}>
              <Text style={styles.textInfo}>
                {changePalabra(info.semestre)}
              </Text>
              <Text style={styles.textInfo}>{info.grupo_ident}</Text>
              <Text style={styles.textInfo}>ESCOLARIZADA</Text>
              <Text style={styles.textInfo}>MATUTINO</Text>
            </View>
          </View>
        </View>
        <View style={[styles.tableStyle]}>
          <View
            style={[
              styles.tableRowStyle,
              { marginLeft: "5px", marginRight: "5px" },
            ]}
            fixed
          >
            <View style={[styles.firstchild, { width: "4%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                No.
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "13%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                Matricula
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "18%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                Primer Apellido
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "18%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                Segundo Apellido
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "24%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                Nombre
              </Text>
            </View>
            <View
              style={[
                styles.tableColHeaderStyle,
                { width: "24%", display: "flex", flexDirection: "column" },
              ]}
            >
              <View>
                <Text style={[styles.tableCellHeaderStyle]}>Calificación</Text>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  borderTopStyle: "solid",
                  borderTopColor: "#000",
                  borderTopWidth: 1,
                }}
              >
                <View
                  style={{
                    width: "35%",
                  }}
                >
                  <Text style={[styles.tableCellHeaderStyle]}>Número</Text>
                </View>
                <View
                  style={{
                    width: "65%",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#000",
                    borderLeftWidth: "thick",
                    borderLeftWidth: 1,
                  }}
                >
                  <Text style={[styles.tableCellHeaderStyle]}>Letra</Text>
                </View>
              </View>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "12%" }]}>
              <Text
                style={[
                  styles.tableCellHeaderStyle,
                  { marginBottom: 0, lineHeight: 1 },
                ]}
              >
                % de
              </Text>
              <Text style={[styles.tableCellHeaderStyle]}>Asistencias</Text>
            </View>
          </View>
          {notas.map((nota, ind) => {
            return (
              <View
                key={`${nota.id_dist_estu}`}
                id={`${nota.id_dist_estu}`}
                style={[
                  styles.tableRowStyle,
                  { marginLeft: "5px", marginRight: "5px" },
                  ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                ]}
                fixed
              >
                <View
                  style={[
                    styles.tableColStyle,
                    {
                      width: "4%",
                      borderLeftStyle: "solid",
                      borderLeftColor: "#000",
                      borderLeftWidth: 1,
                    },
                  ]}
                >
                  <Text style={[styles.textCell]}>{ind + 1}</Text>
                </View>
                <View style={[styles.tableColStyle, { width: "13%" }]}>
                  <Text style={[styles.textCell]}>
                    {nota.numero_de_control}
                  </Text>
                </View>
                <View style={[styles.tableColStyle, { width: "18%" }]}>
                  <Text
                    style={[
                      styles.textCell,
                      {
                        textAlign: "left",
                        marginLeft: "1px",
                      },
                    ]}
                  >
                    {nota.apellido_paterno}
                  </Text>
                </View>
                <View style={[styles.tableColStyle, { width: "18%" }]}>
                  <Text
                    style={[
                      styles.textCell,
                      {
                        textAlign: "left",
                        marginLeft: "1px",
                      },
                    ]}
                  >
                    {nota.apellido_materno}
                  </Text>
                </View>
                <View style={[styles.tableColStyle, { width: "24%" }]}>
                  <Text
                    style={[
                      styles.textCell,
                      {
                        textAlign: "left",
                        marginLeft: "1px",
                      },
                    ]}
                  >
                    {nota.first_name}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColStyle,
                    {
                      width: "8.5%",
                    },
                  ]}
                >
                  <Text style={[styles.textCell]}>
                    {nota.ordinario.nota_final}
                  </Text>
                </View>
                <View
                  style={[
                    styles.tableColStyle,
                    {
                      width: "15.5%",
                    },
                  ]}
                >
                  <Text style={[styles.textCell]}>
                    {numb_to_letter(nota.ordinario.nota_final)}
                  </Text>
                </View>
                <View style={[styles.tableColStyle, { width: "12%" }]}>
                  <Text style={[styles.textCell]}>
                    {nota.ordinario.porcentaje_de_asistencia}
                  </Text>
                </View>
              </View>
            );
          })}
        </View>

        <View
          style={{
            position: "absolute",
            bottom: "1.8cm",
            left: 0,
            right: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
          wrap={false}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text style={styles.textBold}>
                {entidad?.clave_del_centro === "13DNL0004E"
                  ? "MTRO. RICARDO CARPIO SAGAHÓN"
                  : "MTRA. CASANDRA ALHELI ORTEGA RONDERO"}
              </Text>
              <Svg height="3" width="300">
                <Line
                  x1={50}
                  x2={250}
                  y1={0}
                  y2={0}
                  stroke="black"
                  strokeWidth={2}
                />
              </Svg>
              <Text style={styles.textFooterRed}>JEFE CONTROL ESCOLAR</Text>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Text style={styles.textBold}>{info.profesor_name}</Text>
              <Svg height="3" width="300">
                <Line
                  x1={50}
                  x2={250}
                  y1={0}
                  y2={0}
                  stroke="black"
                  strokeWidth={2}
                />
              </Svg>
              <Text style={styles.textFooterRed}>PROFESOR (A)</Text>
            </View>
          </View>
          <View
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text style={styles.textBold}>
              {entidad?.clave_del_centro === "13DNL0004E"
                ? "MTRO. JOSÉ CUATEPOTZO COSTEIRA"
                : "MTRA. MARÍA DE JESÚS LEINES SOLARES"}
            </Text>
            <Svg height={3}>
              <Line
                x1={200}
                x2={400}
                y1={0}
                y2={0}
                stroke="black"
                strokeWidth={2}
              />
            </Svg>
            <Text style={styles.textFooterRed}>DIRECTOR DEL PLANTEL</Text>
          </View>
        </View>
        {imagenes.footer !== null ? (
          <View
            style={{
              position: "absolute",
              bottom: "0.2cm",
              left: 0,
              right: 0,
            }}
            wrap={false}
          >
            <Image
              component="img"
              src={imagenes.footer}
              variant="gradient"
              style={{
                height: "1.3cm",
              }}
            />
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default ActaAcreditacion;
