import { HOME_URL } from "config/bases_url";
import ErrorHandling, { ErrorTrue } from "config/error_handling";
import { useSnackbar } from "notistack";
import { useEffect } from "react";

const { useNavigate } = require("react-router-dom");

/**
 * Hook para el manejo de errores dentro de la app, así como el manejo de redirecciones una vez realizada
 * la query designada.
 *
 * @param {boolean} isLoading Variable de control de eventos de RTK Query.
 * @param {boolean} isSuccess Variable de control de eventos de RTK Query.
 * @param {boolean} isError Variable de control de eventos de RTK Query.
 * @param {object} error Objeto de error en caso de existir (este se manda el de RTK Query).
 * @param {string} successMessage Dato tipo string el cual se va a mostrar en la notificación una vez resuelta la petición.
 * @param {string} [customUrl] Dato de tipo string al cual se va a redireccionar una vez cumplida la query o mutación. Opcional si `redirect` es false.
 * @param {Function} handleClose Función para controlar los modals. Es requerida si `redirect` es false.
 * @param {boolean} [redirect=true] Variable para controlar si se hace una redirección. Si no se pasa, es true por default. Si se pasa, es necesario incluir `handleClose` cuando sea false.
 */

export const CANCEL_NAVIGATION = "cancel_navigation";

export function showErrorMessages(error, enqueueSnackbar) {
  function dfs(value) {
    if (typeof value === "string") {
      enqueueSnackbar(value, {
        variant: "error",
        autoHideDuration: 3000,
      });
    } else if (typeof value === "object") {
      for (const key in value) {
        const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
        dfs(value[formattedKey]);
      }
    } else if (Array.isArray(value)) {
      value.forEach((el) => dfs(el));
    }
  }

  if (Array.isArray(error.data)) {
    error.data.forEach((el) => dfs(el));
  } else if (typeof error.data === "object") {
    Object.values(error.data).forEach((value) => dfs(value));
  } else if (ErrorTrue(error.status)) {
    enqueueSnackbar(ErrorHandling(error.status), { variant: "error" });
  } else {
    enqueueSnackbar("Ha ocurrido un error inesperado", {
      variant: "error",
    });
  }
}

export const useRedirectForm = (
  isLoading,
  isSuccess,
  isError,
  error,
  successMessage,
  customUrl
) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      if (customUrl !== CANCEL_NAVIGATION) {
        const url = customUrl || HOME_URL; // Usar URL personalizada si está definida, de lo contrario usar URL predeterminada
        navigate(url);
      }

      successMessage &&
        enqueueSnackbar(successMessage, {
          variant: "success",
          autoHideDuration: 5000,
        });
    }

    if (isError) {
      showErrorMessages(error, enqueueSnackbar);
    }
  }, [isLoading]);
};
