/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useState, Suspense } from "react";

// react-router components
import { Route, Routes, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from "context";

//Componente para las rutas de los forms
import MainLayout from "layouts/main.layout";
import NewUser from "layouts/almacen/pages/new-user/new_user";
import NewEntity from "layouts/almacen/pages/new-entidad/new_entidad";
import DataTableWithTabs from "layouts/escuela/data-tables/calificaciones";

//Componentes de seguridad
import PrivateRoutes from "config/private_routes";
import ComponentsPrivatization from "config/component_privatization";

//Roles y rutas
import {
  AUXILIAR_DOCENTE,
  COORDINADOR_DOCENTE,
  ESTUDIANTE,
  FIRMANTE_BOLETA,
  PROFESOR,
} from "roles";
import {
  ENTIDAD_EDIT_URL,
  ENTIDAD_NEW_URL,
  GRUPOS_LIST_URL,
  USER_EDIT_URL,
  USER_NEW_URL,
} from "config/bases_url";

import { checkPerm, toIsoDate } from "helpers/functions";
import EditCiclo from "layouts/escuela/pages/edit-ciclo/edit_ciclo";
import { DataTableGrupos } from "layouts/escuela/data-tables";
import { useLazyGetCiclosQuery } from "services";
import { useSelector } from "react-redux";
import { auth_state } from "slices";
import { Icon } from "@mui/material";
import { privateRoutes } from "./config/app-router/private-routes";
import { publicRoutes } from "./config/app-router/public-routes";
import MySuspense from "./config/suspense";
export default function App() {
  const [controller] = useMaterialUIController();
  const { direction, darkMode } = controller;
  const { pathname } = useLocation();
  const [stateRoutes, setStateRoutes] = useState(routes);
  const { authenticated, user_info: userAuth } = useSelector(auth_state);
  const [getCiclos] = useLazyGetCiclosQuery();

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const fetchCiclos = async () => {
      if (authenticated) {
        const res = await getCiclos()
          .unwrap()
          .then((data) =>
            data?.map((el, ind) => {
              return (
                el.activo && {
                  name: `${toIsoDate(el.fecha_inicio, true)}-${toIsoDate(
                    el.fecha_fin,
                    true
                  )}`,
                  key: el.id,
                  icon: <Icon fontSize="medium">event_available</Icon>,
                  roles: [
                    COORDINADOR_DOCENTE,
                    AUXILIAR_DOCENTE,
                    PROFESOR,
                    FIRMANTE_BOLETA,
                  ],
                  collapse: [
                    el.activo && {
                      name: "Editar",
                      key: "editar-ciclo",
                      type: "item",
                      icon: <Icon fontSize="medium">edit</Icon>,
                      roles: [COORDINADOR_DOCENTE],
                      route: `/ciclos/${toIsoDate(
                        el.fecha_inicio,
                        true
                      )}-${toIsoDate(el.fecha_fin, true)}/editar/${el.id}`,
                      component: <EditCiclo id={el.id} />,
                    },
                    {
                      name: "Grupos",
                      key: `grupos-${ind + 1}`,
                      type: "item",
                      icon: <Icon fontSize="medium">groups</Icon>,
                      roles: [
                        COORDINADOR_DOCENTE,
                        AUXILIAR_DOCENTE,
                        FIRMANTE_BOLETA,
                      ],
                      route: `${GRUPOS_LIST_URL}/${toIsoDate(
                        el.fecha_inicio,
                        true
                      )}-${toIsoDate(el.fecha_fin, true)}/grupos`,
                      component: (
                        <DataTableGrupos
                          tableHeader="Grupos"
                          ciclo_id={el.id}
                          cicle_name={`${toIsoDate(
                            el.fecha_inicio,
                            true
                          )}-${toIsoDate(el.fecha_fin, true)}`}
                          activo={el.activo}
                        />
                      ),
                    },
                    {
                      name: "Calificaciones",
                      type: "item",
                      icon: <Icon fontSize="medium">school</Icon>,
                      key: `calificaciones`,
                      roles: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE, PROFESOR],
                      route: `/ciclos/${toIsoDate(
                        el.fecha_inicio,
                        true
                      )}-${toIsoDate(el.fecha_fin, true)}/calificaciones`,
                      component: (
                        <DataTableWithTabs
                          ciclo_id={el.id}
                          activo={el.activo}
                          cicle_name={`${toIsoDate(
                            el.fecha_inicio,
                            true
                          )}-${toIsoDate(el.fecha_fin, true)}`}
                          ind={ind}
                          ciclo_url={el.url}
                        />
                      ),
                    },
                  ],
                }
              );
            })
          );
        const historicos = await getCiclos()
          .unwrap()
          .then((data) => {
            return {
              type: "collapse",
              name: "Históricos",
              key: "historicos",
              roles: [COORDINADOR_DOCENTE, PROFESOR, AUXILIAR_DOCENTE],
              icon: <Icon fontSize="medium">hourglass_empty</Icon>,
              collapse: data?.map((el, ind) => {
                return (
                  !el.activo && {
                    name: `${toIsoDate(el.fecha_inicio, true)}-${toIsoDate(
                      el.fecha_fin,
                      true
                    )}`,
                    key: el.id,
                    icon: <Icon fontSize="medium">event_unavalible</Icon>,
                    roles: [
                      COORDINADOR_DOCENTE,
                      AUXILIAR_DOCENTE,
                      PROFESOR,
                      FIRMANTE_BOLETA,
                    ],
                    collapse: [
                      {
                        name: "Grupos",
                        key: `grupos-${ind + 1}`,
                        type: "item",
                        icon: <Icon fontSize="medium">groups</Icon>,
                        roles: [
                          COORDINADOR_DOCENTE,
                          AUXILIAR_DOCENTE,
                          FIRMANTE_BOLETA,
                        ],
                        route: `${GRUPOS_LIST_URL}/${toIsoDate(
                          el.fecha_inicio,
                          true
                        )}-${toIsoDate(el.fecha_fin, true)}/grupos`,
                        component: (
                          <DataTableGrupos
                            tableHeader="Grupos"
                            ciclo_id={el.id}
                            cicle_name={`${toIsoDate(
                              el.fecha_inicio,
                              true
                            )}-${toIsoDate(el.fecha_fin, true)}`}
                            activo={el.activo}
                          />
                        ),
                      },
                      {
                        type: "item",
                        icon: <Icon fontSize="medium">school</Icon>,
                        name: "Calificaciones",
                        key: "calificaciones-2",
                        roles: [
                          COORDINADOR_DOCENTE,
                          AUXILIAR_DOCENTE,
                          PROFESOR,
                        ],
                        route: `/ciclos/${toIsoDate(
                          el.fecha_inicio,
                          true
                        )}-${toIsoDate(el.fecha_fin, true)}/calificaciones`,
                        component: (
                          <DataTableWithTabs
                            ciclo_id={el.id}
                            activo={el.activo}
                            cicle_name={`${toIsoDate(
                              el.fecha_inicio,
                              true
                            )}-${toIsoDate(el.fecha_fin, true)}`}
                            carreras={el.carreras}
                            ind={ind}
                          />
                        ),
                      },
                    ],
                  }
                );
              }),
            };
          });
        setStateRoutes((prevRoutes) =>
          prevRoutes.map((route) => {
            if (route.key === "ciclos") {
              const newRoute = { ...route };
              newRoute.collapse = [
                ...res.sort(function (a, b) {
                  return b.key - a.key;
                }),
                historicos,
              ];
              return newRoute;
            }

            return route;
          })
        );
      }
    };
    if (
      !userAuth?.is_staff &&
      !userAuth?.groups.some((e) => e.name === ESTUDIANTE)
    )
      fetchCiclos();
  }, [authenticated]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (
        (route.route && route.for_all) ||
        (route.route && checkPerm(route.roles))
      ) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  const generateInternalRoutes = (
    routes,
    transformRoute = (route) => route.element
  ) =>
    routes.map((route) => (
      <Route
        exact
        path={route.path}
        element={
          <Suspense fallback={<MySuspense />}>{transformRoute(route)}</Suspense>
        }
        key={route.path}
      />
    ));

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Suspense fallback={<MySuspense />}>
        <Routes>
          {generateInternalRoutes(publicRoutes)}
          <Route element={<PrivateRoutes />}>
            <Route element={<MainLayout routes={stateRoutes} />}>
              {getRoutes(stateRoutes)}
              {generateInternalRoutes(privateRoutes, (route) =>
                route.permitted ? (
                  <ComponentsPrivatization
                    permitted={route.permitted}
                    redirect={true}
                  >
                    <Suspense fallback={<MySuspense />}>
                      {route.element}
                    </Suspense>
                  </ComponentsPrivatization>
                ) : (
                  <Suspense fallback={<MySuspense />}>{route.element}</Suspense>
                )
              )}

              {/* Usuarios */}
              <Route
                exact
                path={USER_NEW_URL}
                element={
                  <Suspense fallback={<MySuspense />}>
                    <NewUser />
                  </Suspense>
                }
              />
              <Route
                exact
                path={USER_EDIT_URL + "/:id"}
                element={
                  <Suspense fallback={<MySuspense />}>
                    <NewUser />
                  </Suspense>
                }
              />

              {/* Entidades */}
              <Route
                exact
                path={ENTIDAD_NEW_URL}
                element={
                  <Suspense fallback={<MySuspense />}>
                    <NewEntity />
                  </Suspense>
                }
              />
              <Route
                exact
                path={ENTIDAD_EDIT_URL + "/:id"}
                element={
                  <Suspense fallback={<MySuspense />}>
                    <NewEntity />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
}
