import { lazy } from "react";
import {
  ACTIVO_CLONE_URL,
  ACTIVO_EDIT_URL,
  ACTIVO_NEW_URL,
  ACTIVO_RESGUARDAR_URL,
  ADQ_EDIT_URL,
  ADQ_NEW_URL,
  ASIGNATURAS_EDIT_URL,
  CARRERAS_EDIT_URL,
  CARRERAS_NEW_URL,
  CICLOS_EDIT_URL,
  CONSUMIBLE_CLONE_URL,
  CONSUMIBLES_EDIT_URL,
  CONSUMIBLES_NEW_URL,
  CURSOS_EDIT_URL,
  CURSOS_NEW_URL,
  CURSOS_SHOW_URL,
  DISTRIBUCIONES_URL,
  ESTADO_EDIT_URL,
  ESTADO_NEW_URL,
  ESTUDIANTES_EDIT_URL,
  ESTUDIANTES_NEW_URL,
  EXAMEN_ADD_URL,
  EXAMEN_CALIFICACION_SHOW_URL,
  EXAMEN_EDIT_URL,
  FIRMANTES_EDIT_URL,
  FIRMANTES_NEW_URL,
  GRUPOS_EDIT_URL,
  GRUPOS_NEW_URL,
  GRUPOS_PROMOVER_URL,
  GRUPOS_SHOW_URL,
  HOME_URL,
  REGISTRO_EDIT_URL,
  REGISTRO_NEW_URL,
  TIPO_EDIT_URL,
  TIPO_NEW_URL,
  UNIDADES_EDIT_URL,
  UNIDADES_NEW_URL,
  MODELOS_EDIT_URL,
} from "../bases_url";
import {
  AUXILIAR_ALMACEN,
  AUXILIAR_DOCENTE,
  CONSTANCIAS,
  COORDINADOR_ALMACEN,
  COORDINADOR_DOCENTE,
  FIRMANTE_BOLETA,
  FIRMANTE_CURSOS,
  PROFESOR,
  RECURSOS_HUMANOS,
} from "../../roles";

// Lazy imports
const Home = lazy(() => import("../../layouts/home/home"));
const NewActivo = lazy(
  () => import("../../layouts/almacen/pages/new-activo/new_activo")
);
const NewConsumible = lazy(
  () => import("../../layouts/almacen/pages/new-consumible/new_consumible")
);
const ActivoResguardar = lazy(
  () => import("../../layouts/almacen/pages/activo-resguardar/ActivoResguardar")
);
const NewAdq = lazy(
  () => import("../../layouts/almacen/pages/new-adq/new_adq")
);
const NewEstadoActivo = lazy(
  () => import("../../layouts/almacen/pages/new-estado-activo/new_estado")
);
const NewTipo = lazy(
  () => import("../../layouts/almacen/pages/new-tipo/new_tipo")
);
const NewUnidad = lazy(
  () => import("../../layouts/almacen/pages/new-unidad/new_unidad")
);
const NewEstudiante = lazy(
  () => import("../../layouts/escuela/pages/new-student/new.student")
);
const NewRegistro = lazy(
  () => import("../../layouts/escuela/pages/new-registro/new_registro")
);
const NewFirmante = lazy(
  () => import("../../layouts/escuela/pages/new-firmante/new_firmante")
);
const NewGrupoEscolar = lazy(
  () => import("../../layouts/escuela/pages/new-grupo_escolar/new.grupoEscolar")
);
const PromoverGrupoEscolar = lazy(
  () => import("../../layouts/escuela/pages/promover-grupo/promover")
);
const GrupoShow = lazy(
  () => import("../../layouts/escuela/data-tables/grupos/grupoShow")
);
const Distribucion = lazy(
  () => import("../../layouts/escuela/pages/new-distribucion/new.distribucion")
);
const EditCiclo = lazy(
  () => import("../../layouts/escuela/pages/edit-ciclo/edit_ciclo")
);
const NewCarrera = lazy(
  () => import("../../layouts/escuela/pages/new-carrera/new.carrera")
);
const NewCurso = lazy(
  () => import("../../layouts/escuela/pages/new-curso/new.curso")
);
const NewAsignatura = lazy(
  () => import("../../layouts/escuela/pages/new-asignatura/new.asignatura")
);
const CursoShow = lazy(
  () => import("../../layouts/escuela/data-tables/cursos/components/curso_show")
);
const NewExamenForm = lazy(
  () => import("../../layouts/escuela/pages/new-examen/new-examen.form")
);
const ExamenShow = lazy(
  () =>
    import(
      "../../layouts/escuela/data-tables/calificaciones/components/examen-evaluacion/ExamenShow"
    )
);
const EditarModeloPDF = lazy(
  () => import("layouts/escuela/data-tables/modelos_pdf/edit_plantilla")
);

export const privateRoutes = [
  //Home
  {
    path: HOME_URL,
    element: <Home />,
  },
  //Consumibles
  {
    path: CONSUMIBLES_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewConsumible />,
  },
  {
    path: CONSUMIBLES_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewConsumible />,
  },
  {
    path: CONSUMIBLE_CLONE_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewConsumible />,
  },
  //Activos
  {
    path: ACTIVO_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewActivo />,
  },
  {
    path: ACTIVO_CLONE_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewActivo />,
  },
  {
    path: ACTIVO_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewActivo />,
  },
  {
    path: ACTIVO_RESGUARDAR_URL,
    permitted: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
    element: <ActivoResguardar />,
  },
  //Adquisiciones
  {
    path: ADQ_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewAdq />,
  },
  {
    path: ADQ_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewAdq />,
  },
  //Estados de activos
  {
    path: ESTADO_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewEstadoActivo />,
  },
  {
    path: ESTADO_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewEstadoActivo />,
  },
  //Catalogo de tipos de activos
  {
    path: TIPO_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewTipo />,
  },
  {
    path: TIPO_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewTipo />,
  },
  //Unidades de medida
  {
    path: UNIDADES_NEW_URL,
    permitted: COORDINADOR_ALMACEN,
    element: <NewUnidad />,
  },
  {
    path: UNIDADES_EDIT_URL(),
    permitted: COORDINADOR_ALMACEN,
    element: <NewUnidad />,
  },
  //Estudiantes
  {
    path: ESTUDIANTES_NEW_URL,
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <NewEstudiante />,
  },
  {
    path: ESTUDIANTES_EDIT_URL(),
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <NewEstudiante />,
  },
  //Profesores
  {
    path: REGISTRO_NEW_URL,
    permitted: [COORDINADOR_DOCENTE, RECURSOS_HUMANOS],
    element: <NewRegistro />,
  },
  {
    path: REGISTRO_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <NewRegistro />,
  },
  //Firmantes
  {
    path: FIRMANTES_NEW_URL,
    permitted: COORDINADOR_DOCENTE,
    element: <NewFirmante />,
  },
  {
    path: FIRMANTES_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <NewFirmante />,
  },
  //Grupos
  {
    path: GRUPOS_NEW_URL,
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <NewGrupoEscolar />,
  },
  {
    path: GRUPOS_EDIT_URL(),
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <NewGrupoEscolar />,
  },
  {
    path: GRUPOS_PROMOVER_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <PromoverGrupoEscolar />,
  },
  {
    path: GRUPOS_SHOW_URL(),
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE, FIRMANTE_BOLETA],
    element: <GrupoShow />,
  },
  //Distribuciones
  {
    path: `${DISTRIBUCIONES_URL}/grupo/:id/ciclo_id/:ciclo_id`,
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <Distribucion />,
  },
  {
    path: `${DISTRIBUCIONES_URL}/grupo/:id/asignatura/:idAsig`,
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <Distribucion />,
  },
  {
    path: `${DISTRIBUCIONES_URL}/grupo/:id/ciclo_id/:ciclo_id/estudiante/:idEst`,
    permitted: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    element: <Distribucion />,
  },
  {
    path: ASIGNATURAS_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <NewAsignatura />,
  },
  //Carreras
  {
    path: CARRERAS_NEW_URL,
    permitted: COORDINADOR_DOCENTE,
    element: <NewCarrera />,
  },
  {
    path: CARRERAS_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <NewCarrera />,
  },
  //Cursos
  {
    path: CURSOS_NEW_URL,
    permitted: [COORDINADOR_DOCENTE, CONSTANCIAS],
    element: <NewCurso />,
  },
  {
    path: CURSOS_EDIT_URL(),
    permitted: [COORDINADOR_DOCENTE, CONSTANCIAS],
    element: <NewCurso />,
  },
  {
    path: CURSOS_SHOW_URL(),
    permitted: [COORDINADOR_DOCENTE, FIRMANTE_CURSOS, CONSTANCIAS],
    element: <CursoShow />,
  },
  //Ciclos
  {
    path: CICLOS_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <EditCiclo />,
  },
  {
    path: EXAMEN_CALIFICACION_SHOW_URL(),
    permitted: [COORDINADOR_DOCENTE, PROFESOR],
    element: <ExamenShow />,
  },
  // {
  //   path: REGULARIZACION_SHOW_URL,
  //   permitted: [COORDINADOR_DOCENTE, PROFESOR],
  //   element: <FirstRegularizacionShow />,
  // },
  // {
  //   path: REGULARIZACION2_SHOW_URL,
  //   permitted: [COORDINADOR_DOCENTE, PROFESOR],
  //   element: <SecondRegularizacionShow />,
  // },

  //Examenes
  {
    path: EXAMEN_ADD_URL,
    permitted: COORDINADOR_DOCENTE,
    element: <NewExamenForm />,
  },
  {
    path: EXAMEN_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <NewExamenForm />,
  },
  {
    path: MODELOS_EDIT_URL(),
    permitted: COORDINADOR_DOCENTE,
    element: <EditarModeloPDF />,
  },
];
