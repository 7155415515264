import { actasApi } from "../view/actas.service";
import { evaluacionesApi } from "../../distribuciones/distribucionDeEvaluaciones.service";
import { asignaturasPorProfesorApi } from "../../distribuciones/distribucionDeAsignaturasPorProfesor.service";
import { appendFormData } from "helpers/functions";

export const subirActa = actasApi.injectEndpoints({
  endpoints: (builder) => ({
    subirActa: builder.mutation({
      query: ({ id, ...rest }) => {
        const formData = new FormData();
        Object.entries(rest).forEach(([key, value]) => {
          appendFormData(formData, value, key);
        });
        return {
          url: "/v1/api/acta/",
          method: "POST",
          headers: {
            "Content-Type": "multipart/form-data",
          },
          body: formData,
        };
      },
      invalidatesTags: [{ type: "Actas", id: "LIST" }],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          queryFulfilled.then(() => {
            dispatch(
              evaluacionesApi.util.invalidateTags([
                { type: "GruposPorEvaluaciones", id: "LIST" },
              ])
            );
            dispatch(
              asignaturasPorProfesorApi.util.invalidateTags([
                { type: "AsignaturasPorProfesor", id: "LIST" },
                { type: "AsignaturasPorProfesor", id: args.id },
              ])
            );
          });
        } catch (error) {
          console.log(error);
        }
      },
    }),
  }),
});

export const { useSubirActaMutation } = subirActa;
