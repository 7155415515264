// Images
import cursosCren from "assets/images/tecnoschool/cren_benito/cursosCren.png";
import sierra_cursos from "assets/images/tecnoschool/sierra/sierra_cursos.png";
import firmaJefe from "assets/images/tecnoschool/cren_benito/firmaJefe.png";

import {
  Document,
  Page,
  Image,
  Text,
  Font,
  StyleSheet,
  View,
  Svg,
  Line,
} from "@react-pdf/renderer";
import Montserrat from "../../../../assets/fonts/Montserrat/static/Montserrat-Bold.ttf";
import MontserratBoldItalic from "../../../../assets/fonts/Montserrat/static/Montserrat-BoldItalic.ttf";
import Montserrat_Italic from "../../../../assets/fonts/Montserrat/static/Montserrat-Italic.ttf";
import Montserrat_Light from "../../../../assets/fonts/Montserrat/static/Montserrat-Light.ttf";
import Montserrat_Regular from "../../../../assets/fonts/Montserrat/static/Montserrat-Regular.ttf";
import Montserrat_Thin from "../../../../assets/fonts/Montserrat/static/Montserrat-Thin.ttf";
import Montserrat_ThinItalic from "../../../../assets/fonts/Montserrat/static/Montserrat-ThinItalic.ttf";
import {
  changePalabra,
  chunkSubstr,
  fechaConLetras,
} from "../../../../helpers/functions";
import { ParidadPorSemestre } from "layouts/escuela/data-tables/grupos/grupoShow";

Font.register({
  family: "Montserrat",
  fonts: [
    { src: Montserrat, fontWeight: "bold" },
    { src: MontserratBoldItalic, fontStyle: "italic", fontWeight: "bold" },
    { src: Montserrat_Italic, fontStyle: "italic" },
    { src: Montserrat_Light, fontWeight: "light" },
    { src: Montserrat_Regular, fontWeight: "normal", fontStyle: "normal" },
    { src: Montserrat_Thin, fontWeight: "thin" },
    { src: Montserrat_ThinItalic, fontStyle: "italic", fontWeight: "thin" },
  ],
});

const styles = StyleSheet.create({
  textBold: {
    fontFamily: "Montserrat",
    fontWeight: "bold",
    fontSize: "10pt",
    lineHeight: "1.2",
    textTransform: "uppercase",
  },
  textInfoTitle: {
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    lineHeight: "1.8",
    fontWeight: "bold",
  },
  textInfo: {
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    marginLeft: "5px",
    lineHeight: "1.8",
    fontWeight: "normal",
    fontStyle: "normal",
  },
  textFooterRed: {
    fontFamily: "Montserrat",
    fontSize: "7pt",
    textTransform: "uppercase",
    fontWeight: "normal",
    fontStyle: "normal",
    color: "#880027",
  },
  texWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginTop: "15px",
  },
  textDetallesFooter: {
    fontFamily: "Montserrat",
    fontSize: "6pt",
    lineHeight: "1.2",
    fontWeight: "normal",
    direction: "rtl",
  },
  infoIntermedia: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    textAlign: "left",
  },
  botton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  bottonOtro: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  bottonSpaceEvenly: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
  bottonCertificado: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
  },
  tableStyle: {
    display: "flex",
    alignItems: "center",
    margin: "10px auto",
    width: "98%",
  },
  tableRowStyle: {
    flexDirection: "row",
  },
  firstchild: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
  },
  tableColHeaderStyle: {
    borderStyle: "solid",
    borderColor: "#000",
    borderBottomColor: "#000",
    borderWidth: 1,
    borderLeftWidth: "thick",
  },
  tableColStyle: {
    borderRightStyle: "solid",
    borderRightColor: "#000",
    borderRightWidth: 1,
    borderBottomStyle: "dashed",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    borderTopWidth: "thick",
  },
  tableCellHeaderStyle: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "9pt",
    textTransform: "uppercase",
    fontWeight: "bold",
  },
  textCell: {
    textAlign: "center",
    fontFamily: "Montserrat",
    fontSize: "8pt",
    textTransform: "uppercase",
    fontStyle: "normal",
    fontWeight: "normal",
    marginVertical: "2px",
  },
});

const BoletaFirmada = ({ boleta, estudiante, qrImageSrc, firma, imagenes }) => {
  const entidad = JSON.parse(sessionStorage.getItem("user"))?.perfil?.entidad;

  return (
    <Document
      producer="TecnoSchool PDF"
      creator="TecnoSchool"
      author="TecnoSchool"
      title="Boleta de Calificaciones"
      subject="Boleta de Calificaciones Semestrales"
      keywords="boleta, calificaciones, escuela"
      language="es-MX"
      pdfVersion="1.7"
      signer={`${firma.nombre} ${firma.paterno} ${firma.materno}`}
    >
      <Page
        size="LETTER"
        style={{
          paddingLeft: "0.19cm",
          paddingRight: "0.19cm",
          paddingTop: "0.1cm",
        }}
      >
        <View
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            margin: "5px 25px",
            alignItems: "center",
          }}
        >
          <Image
            component="img"
            src={imagenes.header}
            variant="gradient"
            style={{
              height: "1.94cm",
              width: "100%",
            }}
          />
        </View>
        <View style={styles.texWrapper}>
          <Text style={styles.textBold}>{entidad.nombre}</Text>
          <Text style={styles.textBold}>
            SISTEMA DE ACREDITACIÓN Y CERTIFICACIÓN NORMAL
          </Text>
          <Text style={styles.textBold}>
            BOLETA DE CALIFICACIONES SEMESTRALES
          </Text>
          <Text style={styles.textBold}>DEPARTAMENTO DE CONTROL ESCOLAR</Text>
          <Text style={styles.textBold}>
            CICLO ESCOLAR: {estudiante.ciclo}{" "}
            {ParidadPorSemestre(estudiante.semestre)}
          </Text>
        </View>
        <View
          style={{
            marginTop: "15px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginLeft: "10px",
          }}
        >
          <View style={[styles.infoIntermedia, { marginLeft: "1cm" }]}>
            <View>
              <Text style={[styles.textInfoTitle]}>Licenciatura:</Text>
              <Text style={[styles.textInfoTitle]}>Nombre:</Text>
              <Text style={[styles.textInfoTitle]}>Matrícula:</Text>
            </View>
            <View>
              <Text style={styles.textInfo}>{estudiante.carrera}</Text>

              <Text style={styles.textInfo}>
                {estudiante.apellido_paterno} {estudiante.apellido_materno}{" "}
                {estudiante.nombre}
              </Text>
              <Text style={styles.textInfo}>
                {estudiante.numero_de_control}
              </Text>
            </View>
          </View>
          <View style={[styles.infoIntermedia, { marginRight: "2cm" }]}>
            <View>
              <Text style={[styles.textInfoTitle]}>Semestre:</Text>
              <Text style={[styles.textInfoTitle]}>Grupo:</Text>
              <Text style={[styles.textInfoTitle]}>Modalidad:</Text>
            </View>
            <View>
              <Text style={styles.textInfo}>
                {changePalabra(estudiante.semestre)}
              </Text>
              <Text style={styles.textInfo}>{estudiante.grupo}</Text>
              <Text style={styles.textInfo}>Escolarizada</Text>
            </View>
          </View>
        </View>
        <View style={[styles.tableStyle]}>
          <View style={[styles.tableRowStyle]} fixed>
            <View style={[styles.firstchild, { width: "5%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                No.
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "70%" }]}>
              <Text style={[styles.tableCellHeaderStyle, { marginTop: "4pt" }]}>
                Cursos
              </Text>
            </View>
            <View style={[styles.tableColHeaderStyle, { width: "15%" }]}>
              <Text
                style={[
                  styles.tableCellHeaderStyle,
                  { marginBottom: 0.5, lineHeight: 1.5 },
                ]}
              >
                Calificaciones
              </Text>
              <Text style={[styles.tableCellHeaderStyle]}>Número</Text>
            </View>
          </View>
          {boleta.map((nota, ind) => {
            return (
              <View
                key={`nota-${ind}`}
                style={[
                  styles.tableRowStyle,
                  ind % 2 !== 0 ? { backgroundColor: "#eef0f0" } : "",
                ]}
                fixed
              >
                <View
                  style={[
                    styles.tableColStyle,
                    {
                      width: "5%",
                      borderLeftStyle: "solid",
                      borderLeftColor: "#000",
                      borderLeftWidth: 1,
                    },
                  ]}
                >
                  <Text style={[styles.textCell]}>{ind + 1}</Text>
                </View>
                <View style={[styles.tableColStyle, { width: "70%" }]}>
                  <Text
                    style={[
                      styles.textCell,
                      { textAlign: "left", marginLeft: "2px" },
                    ]}
                  >
                    {nota.asignatura}
                  </Text>
                </View>
                <View style={[styles.tableColStyle, { width: "15%" }]}>
                  <Text style={[styles.textCell]}>{nota.nota}</Text>
                </View>
              </View>
            );
          })}
        </View>
        <Text style={[styles.textInfoTitle, { marginLeft: "50%" }]}>
          Promedio por Semestre:{"      "}
          {(
            boleta.reduce((acc, nota) => acc + parseInt(nota.nota), 0) /
            boleta.length
          ).toFixed(2)}
        </Text>
        <View
          style={[
            styles.bottonOtro,
            { marginBottom: "25px", marginTop: "25px" },
          ]}
        >
          <Image
            className="firma"
            src={firma.firma}
            variant="gradient"
            style={{
              maxWidth: "3.5cm",
              maxHeight: "3.5cm",
              marginBottom: "5px",
            }}
          />
          <Text
            style={styles.textInfoTitle}
          >{` ${firma.nombre} ${firma.paterno} ${firma.materno}`}</Text>
          <Text style={[styles.textInfoTitle, { color: "#880027" }]}>
            {firma.cargo}
          </Text>
        </View>
        <View
          style={{
            position: "absolute",
            bottom: "1.8cm",
            left: 0,
            right: "15px",
          }}
        >
          <View style={[styles.bottonSpaceEvenly, { marginLeft: "1.8cm" }]}>
            <Image
              src={qrImageSrc}
              alt="QR Code"
              style={{ width: "3.6cm", height: "3.6cm" }}
            />

            <View
              style={[
                styles.bottonOtro,
                { alignItems: "flex-start", marginLeft: "0.5cm" },
              ]}
            >
              <Text
                style={{
                  fontSize: "10pt",
                }}
              >
                Sello Digital:
              </Text>

              <Text
                style={{
                  marginTop: "8px",
                  maxWidth: "14cm",
                  fontSize: "10pt",
                  textAlign: "justify",
                  fontFamily: "Courier-Bold",
                }}
              >
                {chunkSubstr(firma.sello, 59)?.map((e, index) => {
                  return (
                    <Text
                      key={index}
                      style={{
                        fontFamily: "Courier-Bold",
                      }}
                    >
                      {e}{" "}
                    </Text>
                  );
                })}
              </Text>
            </View>
          </View>
          <Text
            style={{
              fontFamily: "Montserrat",
              fontSize: "8pt",
              lineHeight: "1.8",
              fontWeight: "bold",
              direction: "rtl",
              margin: "5px 10px 5px 0",
            }}
          >
            .{entidad.municipio}, {entidad.localidad},{" "}
            {fechaConLetras(firma.fecha)}
          </Text>
        </View>
        {imagenes.footer !== null ? (
          <View
            style={{
              position: "absolute",
              bottom: "0.2cm",
              left: 0,
              right: 0,
            }}
            wrap={false}
          >
            <Image
              component="img"
              src={imagenes.footer}
              variant="gradient"
              style={{
                height: "1.3cm",
              }}
            />
          </View>
        ) : null}
      </Page>
    </Document>
  );
};

export default BoletaFirmada;
