import { templatesApi } from "../view/modelos.service";

export const deleteTemplateImage = templatesApi.injectEndpoints({
  endpoints: (builder) => ({
    deleteTemplateImage: builder.mutation({
      query: (id) => ({
        url: `/v1/api/templateimage/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error, { id }) =>
        result
          ? [
              { type: "Templates", id },
              { type: "Templates", id: "LIST" },
            ]
          : [{ type: "Templates", id: "LIST" }],
    }),
  }),
});

export const { useDeleteTemplateImageMutation } = deleteTemplateImage;
