import { createRoot } from "react-dom/client";
import _ from "lodash";

export const toIsoDate = (isoDate, onlyYear = false) => {
  // Verifica que la fecha tenga el formato correcto (YYYY-MM-DD)
  if (!/^\d{4}-\d{2}-\d{2}$/.test(isoDate)) return "2000"; // Validación básica

  // Divide la fecha en sus componentes (año, mes, día)
  const [year, month, day] = isoDate.split("-").map(Number);

  // Crea un objeto Date usando los componentes de la fecha en UTC
  const date = new Date(Date.UTC(year, month - 1, day)); // Los meses en JavaScript son base 0

  // Verifica si la fecha es válida
  if (isNaN(date)) return "2000";

  // Formatea la fecha en UTC para evitar problemas de zona horaria
  const formatter = new Intl.DateTimeFormat("es-ES", {
    timeZone: "UTC", // Forzar el uso de UTC
    ...(onlyYear
      ? { year: "numeric" }
      : {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        }),
  });

  return formatter.format(date);
};

export const buildFiltersUrl = (base, params) => {
  const query = new URLSearchParams(_.pickBy(params)).toString();
  return `${base}?${query}`;
};

export const checkPerm = (permitted) => {
  const user = JSON.parse(sessionStorage.getItem("user"));
  return _.isArray(permitted)
    ? _.some(user?.groups, (role) => permitted.includes(role.name))
    : _.isString(permitted)
      ? _.some(user?.groups, (role) => role.name === permitted)
      : user?.is_staff;
};

// Tranformar la respuesta final a una forma especifica pasando un objeto shape con las configuraciones pertinentes
export function reshape_response(response, shape) {
  function reshape(response, shape) {
    return Object.keys(shape).reduce((reshapedData, key) => {
      const transformer = shape[key];
      reshapedData[key] = resolvePath(response, transformer);
      return reshapedData;
    }, {});
  }

  function resolvePath(response, path) {
    if (typeof path === "function") {
      return path(response);
    } else if (path.includes(" ")) {
      const parts = path.split(" ");
      return parts.map((part) => resolveSinglePath(response, part)).join(" ");
    } else {
      return resolveSinglePath(response, path);
    }
  }

  function resolveSinglePath(response, path) {
    const arrayRegex = /\[\d*\]/;
    const pathSegments = path.split(".");

    let currentObject = response;
    for (let i = 0; i < pathSegments.length; i++) {
      const segment = pathSegments[i];

      if (segment.match(arrayRegex)) {
        const [base, index] = segment.split(/[\[\]]/).filter(Boolean);

        if (base) {
          currentObject = currentObject ? currentObject[base] : undefined;
        }

        if (currentObject === undefined) {
          return null;
        }

        if (index !== undefined && index !== "") {
          currentObject = currentObject[Number(index)];
          if (currentObject === undefined) {
            return null;
          }
        } else {
          currentObject = Array.isArray(currentObject)
            ? currentObject.map((item) =>
                resolveSinglePath(item, pathSegments.slice(i + 1).join("."))
              )
            : [];
          break;
        }
      } else {
        currentObject = currentObject ? currentObject[segment] : undefined;
        if (currentObject === undefined) {
          return null;
        }
      }
    }

    return currentObject;
  }

  return reshape(response, shape);
}

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(file);
  });

export const reemplazarUrl = (url, numero) => url.replace(/v\d/, `v${numero}`);

export const formatoAEquivalenteHoras = (formato) => {
  if (!formato) return 0;
  if (formato.length > 8) {
    const [dias = 0, horas] = formato.split(" ");
    return _.isNaN(parseInt(dias)) && _.isNaN(parseInt(horas))
      ? null
      : parseInt(dias) * 24 + parseInt(horas);
  } else {
    return parseInt(formato);
  }
};

export const generarURL = (id, curso) => {
  const urlBase = window.location.origin.includes("herokuapp")
    ? "https://tecnoschool-services-dev.herokuapp.com/"
    : "https://services.tecnoschool.mx/";

  const queryObj = curso ? { curso: id } : { notas: id };
  return `${urlBase}?query=${btoa(JSON.stringify(queryObj))}`;
};

export const chunkSubstr = (str, size) =>
  _.chunk(str, size).map((chunk) => chunk.join(""));

const numberMapForGrades = {
  1: "PRIMERO",
  2: "SEGUNDO",
  3: "TERCERO",
  4: "CUARTO",
  5: "QUINTO",
  6: "SEXTO",
  7: "SÉPTIMO",
  8: "OCTAVO",
  9: "NOVENO",
  10: "DÉCIMO",
};

export const changePalabra = (numb) => numberMapForGrades[numb.toString()];

const numberMap = {
  1: "UNO",
  2: "DOS",
  3: "TRES",
  4: "CUATRO",
  5: "CINCO",
  6: "SEIS",
  7: "SIETE",
  8: "OCHO",
  9: "NUEVE",
  10: "DIEZ",
};

export const numb_to_letter = (numb) => {
  const parts = _.split(_.toString(numb), ".");

  const translatedParts = _.map(parts, (part) => _.get(numberMap, part, ""));

  return _.join(_.compact(translatedParts), " ");
};

// Función que retorna los campos modificados
export const getModifiedFields = (originalData, newData) =>
  _.omitBy(newData, (value, key) => _.isEqual(value, originalData[key]));

export const appendFormData = (formData, data, prefix = "") => {
  const value = Array.isArray(data)
    ? data.length && typeof data[0] !== "object"
      ? data.join(",")
      : JSON.stringify(data)
    : data;
  formData.append(prefix, value);
};

export const transformTelefonos = (telefonos) =>
  telefonos?.map((el) =>
    _.pick(el, el.isCreated ? ["tipo", "telefono"] : ["id", "tipo", "telefono"])
  ) || null;

export const fechaConLetras = (fechaStr) => {
  const [anno, mes, dia] = fechaStr.split("-");
  return `${parseInt(dia, 10)} de ${["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"][parseInt(mes) - 1]} de ${anno}`;
};

export function normalizarCadena(cadena) {
  return _.chain(cadena)
    .deburr() // Elimina tildes y otros diacríticos
    .toLower() // Convierte a minúsculas
    .replace(/\s+/g, "_") // Reemplaza los espacios por _
    .replace(/[^\w_]/g, "") // Elimina caracteres no alfanuméricos
    .value(); // Ejecuta la cadena de funciones
}

export function esImpar(numero) {
  // Convertir el número a entero
  const num = parseInt(numero, 10);

  // Verificar si la conversión fue exitosa y si el número es impar
  return !isNaN(num) && num % 2 !== 0;
}
export const paridadNew = (division) => (esImpar(division) ? "NON" : "PAR");

//las siguientes funciones son necesarias para cargar las imagenes desde direcciones fuera de la pag y sean mostradas correctamente en el pdf
// Función para descargar una imagen como blob y convertirla en una URL local
export const fetchImageAsBlob = async (imageUrl) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error(`Error al cargar la imagen: ${response.statusText}`);
    }
    const blob = await response.blob();
    return URL.createObjectURL(blob); // Convierte el blob en una URL local
  } catch (error) {
    console.error("Error al descargar la imagen:", error);
    throw error;
  }
};

// Función para cargar componentes img para las plantillas
export const loadImages = async (plantilla) => {
  const imageUrls = plantilla.map((e) => e.imagen) || [];
  const localImageUrls = await Promise.all(imageUrls.map(fetchImageAsBlob));
  return localImageUrls.map((url, index) => {
    const espacio = plantilla[index].espacio;
    return (
      <img
        key={index}
        id={`plantilla-${espacio}`}
        style={{ height: "1.94cm", width: "100%" }}
        src={url} // Usa la URL local
      />
    );
  });
};

// Función para convertir una imagen en un canvas
export const imageToCanvas = (imageElement) => {
  if (!imageElement || !imageElement.complete) {
    throw new Error("La imagen no está cargada o no existe.");
  }

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  // Establecer el tamaño del canvas igual al de la imagen
  canvas.width = imageElement.naturalWidth;
  canvas.height = imageElement.naturalHeight;

  // Dibujar la imagen en el canvas
  ctx.drawImage(imageElement, 0, 0);

  return canvas;
};

// Función para esperar a que las imágenes se carguen completamente
export const waitForImagesToLoad = async (imageIds) => {
  return Promise.all(
    imageIds.map((id) => {
      return new Promise((resolve, reject) => {
        const img = document.getElementById(id);

        // Verificar si el elemento existe
        if (!img) {
          console.error(`Elemento con ID '${id}' no encontrado en el DOM.`);
          reject(new Error(`Elemento con ID '${id}' no encontrado en el DOM.`));
          return;
        }

        // Si la imagen ya está cargada, resolvemos inmediatamente
        if (img.complete) {
          resolve(img);
        } else {
          // Esperar a que la imagen se cargue
          img.onload = () => resolve(img);
          img.onerror = (error) => reject(error);
        }
      });
    })
  );
};

// Función principal para cargar y procesar imágenes externas
export const usarImagenesExternasEnPDF = async (plantilla, firmaUrl) => {
  // Cargar las imágenes como blobs y generar sus URLs locales
  const imagenesCargadas = await loadImages(plantilla);

  // Procesar la firma
  const firmaBlob = await fetchImageAsBlob(firmaUrl);

  // Renderizar las imágenes en el DOM (ocultas)
  const container = document.getElementById("image-container");
  if (!container) {
    console.error("El contenedor 'image-container' no existe en el DOM.");
    return;
  }

  // Crear una promesa para esperar a que React termine de renderizar
  await new Promise((resolve) => {
    const root = createRoot(container);
    root.render(
      <div style={{ display: "none" }}>
        {imagenesCargadas}
        <img id="firma-imagen" src={firmaBlob} />
      </div>
    );
    setTimeout(resolve, 100);
  });

  // IDs de las imágenes en el DOM
  const imageIds = ["plantilla-header", "plantilla-footer", "firma-imagen"];

  // Esperar a que las imágenes se carguen
  const [headerImg, footerImg, firmaImg] = await waitForImagesToLoad(imageIds);

  // Convertir las imágenes en canvas
  const headerCanvas = imageToCanvas(headerImg);
  const footerCanvas = imageToCanvas(footerImg);
  const firmaCanvas = imageToCanvas(firmaImg);

  // Obtener las URLs de los canvas
  return {
    header: headerCanvas.toDataURL(),
    footer: footerCanvas.toDataURL(),
    firma: firmaCanvas.toDataURL(),
  };
};
