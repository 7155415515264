/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 All of the routes for the Material Dashboard 2 PRO React are added here,
 You can add a new route, customize the routes and delete the routes here.

 Once you add a new route on this file it will be visible automatically on
 the Sidenav.

 For adding a new route you can follow the existing routes in the routes array.
 1. The `type` key with the `collapse` value is used for a route.
 2. The `type` key with the `title` value is used for a title inside the Sidenav.
 3. The `type` key with the `divider` value is used for a divider between Sidenav items.
 4. The `name` key is used for the name of the route on the Sidenav.
 5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
 6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
 7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
 inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
 8. The `route` key is used to store the route location which is used for the react router.
 9. The `href` key is used to store the external links location.
 10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
 10. The `component` key is used to store the component of its route.
 */

// Material Dashboard 2 PRO React layouts

//Data Tables:
import {
  DataTableAdqs,
  DataTableEstadoActivo,
  DataTableTipos,
  OrderListActivos,
  OrderListActivosBaja,
} from "layouts/almacen/data-tables";

// @mui icons
import Icon from "@mui/material/Icon";
import OrderListActivosResguardo from "layouts/almacen/data-tables/order-list-activos-resguardo/activos_resguardo.list";
import {
  ACTIVOS_BAJAS_LIST_URL,
  ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL,
  ACTIVOS_CONSUMIBLES_URL,
  ACTIVOS_LIST_URL,
  ACTIVOS_RESGUARDOS_LIST_URL,
  ADQ_LIST_URL,
  ASIGNATURAS_LIST_URL,
  BOLETAS_ESTUDIANTES_URL,
  CARRERAS_LIST_URL,
  CERTIFICADO_ELECTRONICO,
  CONSTANCIAS_ESTUDIANTES_URL,
  CURSOS_LIST_URL,
  ENTIDADES_URL,
  ESTADO_ACTIVOS_URL,
  ESTUDIANTES_LIST_URL,
  EXAMEN_LIST_URL,
  MOVIMIENTOS_ASIGNACION_URL,
  MOVIMIENTOS_DEVOLUCION_URL,
  REGISTROS_URL,
  TIPOS_ACTIVOS_URL,
  UNIDADES_URL,
  USUARIOS_URL,
} from "config/bases_url";
import DataTableUsers from "layouts/almacen/data-tables/data-table-usuarios/users.table";
import DataTableEntidades from "layouts/almacen/data-tables/data-table-entidades/entidades.table";
import DataTableMovimientosAsignacion from "layouts/almacen/data-tables/data-table-movimientos/loteAsignacion";
import DataTableMovimientosDevolucion from "layouts/almacen/data-tables/data-table-movimientos/loteDevolucion";
import DataTableUnidades from "layouts/almacen/data-tables/data-table-unidades/unidades.table";
import OrderListActivosConsumibles from "layouts/almacen/data-tables/order-list-activos-consumibles/consumibles.list";
import ConsumiblesResguardo from "layouts/almacen/data-tables/order-list-consumibles-resguardo/consumibles_resguardos";
import {
  DataTableAsignaturas,
  DataTableCarreras,
  DataTableEstudiantes,
  DataTablePlantilla,
} from "./layouts/escuela/data-tables";
import {
  AUXILIAR_ALMACEN,
  AUXILIAR_DOCENTE,
  CONSTANCIAS,
  COORDINADOR_ALMACEN,
  COORDINADOR_DOCENTE,
  ESTUDIANTE,
  FIRMANTE_BOLETA,
  FIRMANTE_CERTIFICADO,
  FIRMANTE_CURSOS,
  PROFESOR,
  RECURSOS_HUMANOS,
} from "roles";
import DataTableCursos from "./layouts/escuela/data-tables/cursos/cursos.table";
import DataTableCursosPerfilEstudiante from "layouts/estudiantes/dataTables/constancias-estudiantes";
import DataTableDocBoletas from "layouts/estudiantes/dataTables/doc-boletas";
import CertificadoElectronico from "layouts/escuela/data-tables/certificado_electronico/e_certificado";
import DataTableExamenes from "./layouts/escuela/data-tables/examenes/examenes.table";
import DataTableModelosPdf from "layouts/escuela/data-tables/modelos_pdf/modelos";
import { MODELOS_LIST_URL } from "config/bases_url";

const routes = [
  {
    type: "title",
    title: "Páginas",
    key: "title-pages",
    roles: [
      COORDINADOR_ALMACEN,
      AUXILIAR_ALMACEN,
      AUXILIAR_DOCENTE,
      COORDINADOR_DOCENTE,
    ],
  },
  {
    type: "collapse",
    name: "Catálogos",
    key: "catalogos",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
    icon: <Icon fontSize="medium">format_list_bulleted</Icon>,
    collapse: [
      {
        icon: <Icon fontSize="medium">list_alt</Icon>,
        type: "item",
        name: "Métodos Adquisición",
        key: "adquisicion",
        route: ADQ_LIST_URL,
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        component: <DataTableAdqs tableHeader="Métodos de Adquisición" />,
      },
      {
        icon: <Icon fontSize="medium">playlist_add_check_circle</Icon>,
        type: "item",
        name: "Estado de Activos",
        key: "estado-activo",
        route: ESTADO_ACTIVOS_URL,
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        component: <DataTableEstadoActivo tableHeader="Estado de Activos" />,
      },
      {
        icon: <Icon fontSize="medium">inventory</Icon>,
        type: "item",
        name: "Tipos de Activos",
        key: "tipos",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        route: TIPOS_ACTIVOS_URL,
        component: <DataTableTipos tableHeader="Tipos de Activos" />,
      },
      {
        icon: <Icon fontSize="medium">scale</Icon>,
        type: "item",
        name: "Unidades de Medida",
        key: "unidades-medida",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        route: UNIDADES_URL,
        component: <DataTableUnidades tableHeader="Unidades de medida" />,
      },
    ],
  },
  {
    type: "divider",
    key: "Divider1",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
  },
  {
    type: "collapse",
    name: "Institución",
    key: "institucion",
    roles: [COORDINADOR_DOCENTE],
    icon: <Icon fontSize="medium">apartment</Icon>,
    collapse: [
      {
        name: "Carreras",
        key: "carreras",
        type: "item",
        icon: <Icon fontSize="medium">app_registration</Icon>,
        route: CARRERAS_LIST_URL,
        roles: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
        component: <DataTableCarreras tableHeader="Carreras" />,
      },
      {
        name: "Asignaturas",
        key: "asignaturas",
        type: "item",
        icon: <Icon fontSize="medium">architecture</Icon>,
        route: ASIGNATURAS_LIST_URL,
        roles: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
        component: <DataTableAsignaturas tableHeader="Asignaturas" />,
      },
    ],
  },
  {
    type: "divider",
    key: "Divider2",
    roles: [COORDINADOR_DOCENTE],
  },
  {
    type: "collapse",
    name: "Configuración",
    key: "configuración",
    roles: [COORDINADOR_DOCENTE],
    icon: <Icon fontSize="medium">settings</Icon>,
    collapse: [
      {
        name: "Exámenes",
        key: "exámenes",
        type: "item",
        icon: <Icon fontSize="medium">pending_actions</Icon>,
        route: EXAMEN_LIST_URL,
        roles: COORDINADOR_DOCENTE,
        component: <DataTableExamenes tableHeader="Tipos de exámenes" />,
      },
      {
        name: "Modelos",
        key: "modelos",
        type: "item",
        icon: <Icon fontSize="medium">pattern</Icon>,
        route: MODELOS_LIST_URL,
        roles: COORDINADOR_DOCENTE,
        component: <DataTableModelosPdf tableHeader="Modelos de pdf" />,
      },
    ],
  },
  { type: "divider", key: "Divider3", roles: [COORDINADOR_DOCENTE] },
  {
    type: "item",
    name: "Estudiantes",
    key: "todos",
    icon: <Icon fontSize="medium">group</Icon>,
    route: ESTUDIANTES_LIST_URL,
    roles: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
    component: <DataTableEstudiantes tableHeader="Estudiantes" />,
  },
  {
    type: "divider",
    key: "Divider4",
    roles: [COORDINADOR_DOCENTE, AUXILIAR_DOCENTE],
  },
  {
    type: "collapse",
    name: "Año Escolar",
    key: "ciclos",
    roles: [COORDINADOR_DOCENTE, PROFESOR, AUXILIAR_DOCENTE, FIRMANTE_BOLETA],
    icon: <Icon fontSize="medium">assignment</Icon>,
    collapse: [],
  },
  {
    type: "divider",
    key: "Divider5",
    roles: [COORDINADOR_DOCENTE, PROFESOR, AUXILIAR_DOCENTE, FIRMANTE_BOLETA],
  },
  {
    type: "collapse",
    name: "Activos",
    key: "activos",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
    icon: <Icon fontSize="medium">storage</Icon>,
    collapse: [
      {
        icon: <Icon fontSize="medium">chair</Icon>,
        type: "collapse",
        name: "Tangibles",
        key: "tangibles",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        collapse: [
          {
            icon: <Icon fontSize="medium">warehouse</Icon>,
            type: "item",
            name: "Almacén",
            key: "almacen",
            route: ACTIVOS_LIST_URL,
            roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
            component: <OrderListActivos tableHeader="Activos en Almacén" />,
          },
          {
            icon: <Icon fontSize="medium">save_as</Icon>,
            type: "item",
            name: "En Resguardo",
            key: "resguardo",
            route: ACTIVOS_RESGUARDOS_LIST_URL,
            roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
            component: (
              <OrderListActivosResguardo tableHeader="Activos en Resguardo" />
            ),
          },
          {
            icon: <Icon fontSize="medium">recycling</Icon>,
            type: "item",
            name: "En Baja",
            key: "bajas",
            roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
            route: ACTIVOS_BAJAS_LIST_URL,
            component: <OrderListActivosBaja tableHeader="Activos en Baja" />,
          },
        ],
      },
      {
        type: "collapse",
        name: "Consumibles",
        key: "consumibles",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        icon: <Icon fontSize="medium">local_dining</Icon>,
        collapse: [
          {
            icon: <Icon fontSize="medium">warehouse</Icon>,
            type: "item",
            name: "Almacén",
            key: "almacen",
            route: ACTIVOS_CONSUMIBLES_URL,
            roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
            component: (
              <OrderListActivosConsumibles tableHeader="Activos Consumibles" />
            ),
          },
          {
            icon: <Icon fontSize="medium">save_as</Icon>,
            type: "item",
            name: "En Resguardo",
            key: "resguardo",
            route: ACTIVOS_CONSUMIBLES_RESGUARDOS_LIST_URL,
            roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
            component: (
              <ConsumiblesResguardo tableHeader="Activos en Resguardo" />
            ),
          },
        ],
      },
    ],
  },
  {
    type: "divider",
    key: "Divider6",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
  },
  {
    type: "collapse",
    name: "Histórico",
    key: "movimientos",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
    icon: <Icon fontSize="medium">article</Icon>,
    collapse: [
      {
        icon: <Icon fontSize="medium">assignment_turned_in</Icon>,
        type: "item",
        name: "Asignaciones",
        key: "asignacion",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        route: MOVIMIENTOS_ASIGNACION_URL,
        component: (
          <DataTableMovimientosAsignacion tableHeader="Asignaciones" />
        ),
      },
      {
        icon: <Icon fontSize="medium">assignment_return</Icon>,
        type: "item",
        name: "Devoluciones",
        key: "devolucion",
        roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
        route: MOVIMIENTOS_DEVOLUCION_URL,
        component: (
          <DataTableMovimientosDevolucion tableHeader="Devoluciones" />
        ),
      },
    ],
  },
  {
    type: "divider",
    key: "Divider7",
    roles: [COORDINADOR_ALMACEN, AUXILIAR_ALMACEN],
  },
  {
    icon: <Icon fontSize="medium">person</Icon>,
    type: "item",
    key: "plantilla",
    name: "Plantilla",
    route: REGISTROS_URL,
    roles: [COORDINADOR_DOCENTE, RECURSOS_HUMANOS],
    component: <DataTablePlantilla tableHeader="Plantilla" />,
  },
  {
    type: "divider",
    key: "Divider8",
    roles: [COORDINADOR_DOCENTE, RECURSOS_HUMANOS],
  },
  {
    icon: <Icon fontSize="medium">books</Icon>,
    type: "collapse",
    key: "extracurricular",
    name: "Extracurricular",
    // roles: [COORDINADOR_DOCENTE, CONSTANCIAS, FIRMANTE_CURSOS, ESTUDIANTE],
    roles: [COORDINADOR_DOCENTE, CONSTANCIAS, FIRMANTE_CURSOS],
    collapse: [
      {
        name: "Cursos",
        key: "cursos",
        type: "item",
        icon: <Icon fontSize="medium">assessment</Icon>,
        route: CURSOS_LIST_URL,
        roles: [COORDINADOR_DOCENTE, CONSTANCIAS, FIRMANTE_CURSOS],
        component: <DataTableCursos tableHeader="Cursos" />,
      },
      // {
      //   name: "Constancias",
      //   key: "constancias",

      //   type: "item",
      //   icon: <Icon fontSize="medium">assessment</Icon>,
      //   route: CONSTANCIAS_ESTUDIANTES_URL,
      //   roles: [ESTUDIANTE],
      //   component: <DataTableCursosPerfilEstudiante tableHeader="Cursos" />,
      // },
    ],
  },
  {
    type: "divider",
    key: "Divider9",
    roles: [COORDINADOR_DOCENTE, CONSTANCIAS, FIRMANTE_CURSOS, ESTUDIANTE],
  },
  {
    icon: <Icon fontSize="medium">sd_storage</Icon>,
    type: "item",
    key: "e-certificado",
    name: "E-Certificado",
    route: CERTIFICADO_ELECTRONICO,
    roles: [FIRMANTE_CERTIFICADO],
    component: <CertificadoElectronico />,
  },
  { type: "divider", key: "Divider10", roles: [FIRMANTE_CERTIFICADO] },
  // {
  //   icon: <Icon fontSize="medium">tab</Icon>,
  //   type: "collapse",
  //   key: "documentos",
  //   name: "Documentos",
  //   roles: [ESTUDIANTE],
  //   collapse: [
  //     {
  //       name: "Boletas",
  //       key: "boletas",
  //       route: BOLETAS_ESTUDIANTES_URL,
  //       roles: [ESTUDIANTE],
  //       component: <DataTableDocBoletas tableHeader="Boletas" />,
  //     },
  //   ],
  // },
  // { type: "divider", key: "Divider11", roles: [ESTUDIANTE] },
  {
    type: "title",
    title: "Administración",
    key: "title-admin",
  },
  {
    icon: <Icon fontSize="medium">accessibility_new</Icon>,
    type: "collapse",
    key: "administracion",
    name: "Administación",
    collapse: [
      {
        icon: <Icon fontSize="medium">account_balance_icon</Icon>,
        type: "item",
        name: "Entidades",
        key: "entidades",
        route: ENTIDADES_URL,
        component: <DataTableEntidades tableHeader="Entidades" />,
      },
      {
        icon: <Icon fontSize="medium">groups</Icon>,
        type: "item",
        name: "Usuarios",
        key: "usuarios",
        route: USUARIOS_URL,
        component: <DataTableUsers tableHeader="Usuarios" />,
      },
    ],
  },
];

export default routes;
