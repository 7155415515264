import {
  FormControl,
  Icon,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import MDButton from "components/MDButton";
import ModalCarpetaCursos from "components/ModalCarpetasCursos/new-carpeta-cursos";
import useModalHandler from "hooks/useModalHandler";
import React from "react";

export const FiltersComponentCarpeta = ({
  carpeta,
  handleChange,
  carpetas,
}) => {
  //Props del modal de carpetas
  const {
    open: openCarpeta,
    handleOpen: handleOpenCarpeta,
    handleClose: handleCloseCarpeta,
  } = useModalHandler();
  return (
    <>
      <ModalCarpetaCursos open={openCarpeta} handleClose={handleCloseCarpeta} />
      <FormControl sx={{ m: 1, minWidth: "14rem" }} size="large">
        <InputLabel id="demo-simple-select-label">Carpeta</InputLabel>
        <Select
          sx={{ minHeight: "37.25px" }}
          labelId="demo-simple-select-label"
          name="carpetas"
          value={carpeta}
          label="Carpetas"
          onChange={handleChange}
        >
          <MenuItem value="">Limpiar</MenuItem>
          {carpetas?.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.nombre}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MDButton
        variant="gradient"
        color="info"
        size="medium"
        onClick={handleOpenCarpeta}
        circular
        iconOnly
        ml={1}
      >
        <Tooltip placement="top" title={"Crear nueva carpeta"}>
          <Icon fontSize={"md"}>add</Icon>
        </Tooltip>
      </MDButton>
    </>
  );
};
