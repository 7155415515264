/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================
 
 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 
 Coded by www.creative-tim.com
 
 =========================================================
 
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "../../../../components/MDBox";
import MDTypography from "../../../../components/MDTypography";

// Material Dashboard 2 PRO React examples
import DataTable from "../../../../examples/Tables/DataTable";

//React Hooks and Others
import { Link, useNavigate } from "react-router-dom";
import { CircularProgress, Icon, Tooltip } from "@mui/material";
import PropTypes from "prop-types";

import ComponentsPrivatization, {
  removeColumns,
} from "../../../../config/component_privatization";
import { style_icon } from "../../../../helpers/global_vars";
import {
  ASIGNATURAS_LIST_URL,
  EXAMEN_ADD_URL,
  EXAMEN_EDIT_URL,
} from "../../../../config/bases_url";
import { useRedirectForm } from "../../../../hooks/useRedirectForm";
import {
  useDeleteExamenMutation,
  useGetExamenesQuery,
} from "../../../../services";
import { COORDINADOR_DOCENTE } from "../../../../roles";
import usePagination from "../../../../hooks/usePagination";
import React from "react";
import { handleToast } from "../../../../helpers/handleToast";
import MDButton from "../../../../components/MDButton";

function DataTableExamenes({ tableHeader }) {
  const navigate = useNavigate();

  const { offset, setOffset, limit, handleNext, handlePrevious } =
    usePagination();

  const {
    data: examenes,
    isError: isErrorG,
    isLoading: isLoadingG,
    error: errorG,
    isFetching,
  } = useGetExamenesQuery(
    {
      filter_params: {
        limit: limit,
        offset: offset,
        query: "{id,examen{nombre},borrable,compartido}",
      },
      shape: {
        id: "id",
        nombre: "examen.nombre",
        borrable: "borrable",
        compartido: "compartido",
      },
    },
    {
      refetchOnReconnect: true,
    }
  );

  //constantes para manejar la paginacion
  const dataExamenes = examenes?.results ? examenes.results : examenes;
  const countTotal = examenes?.count;
  const previous = examenes?.previous;
  const next = examenes?.next;

  const [
    deleteExamen,
    {
      isSuccess: isSuccessD,
      isLoading: isLoadingD,
      isError: isErrorD,
      error: errorD,
    },
  ] = useDeleteExamenMutation();

  const dataTable = {
    columns: [
      {
        Header: "Nombre",
        accessor: "nombre",
        Cell: ({ value }) => {
          return value ? value : "-------------";
        },
      },
      {
        Header: "Acciones",
        accessor: "actions",
        width: "1%",
        Cell: ({ row }) => {
          const { original } = row;

          return (
            <MDBox display="flex">
              <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
                <MDBox px={0.2}>
                  <Tooltip
                    placement="top"
                    title="Editar la información asociada al exámen"
                  >
                    <Icon
                      style={style_icon}
                      onClick={() => handleClickEdit(original)}
                      fontSize="small"
                    >
                      edit
                    </Icon>
                  </Tooltip>
                </MDBox>
                {original.borrable && !original.compartido ? (
                  <MDBox px={0.2}>
                    <Tooltip
                      placement="top"
                      title="Eliminar el exámen del listado"
                    >
                      <Icon
                        style={style_icon}
                        onClick={() =>
                          handleToast({
                            title: "¿Estás seguro?",
                            text: `¡Si eliminas "${original.nombre}", la acción no se podrá revertir!`,
                            dispatcher: (result) => {
                              if (result.isConfirmed) {
                                deleteExamen({ id: original.id });
                              }
                            },
                          })
                        }
                        fontSize="small"
                      >
                        remove_circle
                      </Icon>
                    </Tooltip>
                  </MDBox>
                ) : null}
              </ComponentsPrivatization>
            </MDBox>
          );
        },
      },
    ],

    rows: dataExamenes ?? [],
  };

  useRedirectForm(
    isLoadingG,
    null,
    isErrorG,
    errorG,
    "Hubo problemas para cargar la información",
    ASIGNATURAS_LIST_URL
  );

  useRedirectForm(
    isLoadingD,
    isSuccessD,
    isErrorD,
    errorD,
    "Examen eliminado",
    ASIGNATURAS_LIST_URL
  );

  const handleClickEdit = (el) => {
    navigate(EXAMEN_EDIT_URL(el.id));
  };

  return (
    <MDBox pt={6} pb={3}>
      <Card>
        <MDBox
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          m={2}
          p={3}
        >
          <MDTypography variant="h5" fontWeight="medium">
            {tableHeader}
          </MDTypography>
          <ComponentsPrivatization permitted={COORDINADOR_DOCENTE}>
            <Link to={EXAMEN_ADD_URL}>
              <Tooltip
                placement="top"
                title="Crear un nuevo examen con toda su información"
              >
                <MDButton variant="gradient" color="info" size="medium">
                  nuevo
                </MDButton>
              </Tooltip>
            </Link>
          </ComponentsPrivatization>
        </MDBox>

        <MDBox display="flex" justifyContent={"center"} alignItems={"center"}>
          {isLoadingG || isFetching ? (
            <CircularProgress sx={{ marginBottom: "3rem" }} color="inherit" />
          ) : (
            <DataTable
              table={removeColumns(
                dataTable,
                () =>
                  !JSON.parse(sessionStorage.getItem("user")).groups.some(
                    (role) => role.name === COORDINADOR_DOCENTE
                  ),
                ["actions"]
              )}
              canSearch
              paginationApi
              countTotal={countTotal}
              next={next}
              previous={previous}
              handleNext={handleNext}
              handlePrevious={handlePrevious}
              offset={offset}
              setOffset={setOffset}
            />
          )}
        </MDBox>
      </Card>
    </MDBox>
  );
}

DataTableExamenes.propTypes = {
  tableHeader: PropTypes.string.isRequired,
};

export default DataTableExamenes;
