import ErrorHandling from "config/error_handling";
import { ErrorTrue } from "config/error_handling";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { showErrorMessages } from "./useRedirectForm";

/**
 * Hook para el manejo de errores dentro de la app, así como el manejo de cierre de odals una vez realizada
 * la query designada.
 *
 * @param {boolean} isLoading Variable de control de eventos de RTK Query.
 * @param {boolean} isSuccess Variable de control de eventos de RTK Query.
 * @param {boolean} isError Variable de control de eventos de RTK Query.
 * @param {object} error Objeto de error en caso de existir (este se manda el de RTK Query).
 * @param {string} successMessage Dato tipo string el cual se va a mostrar en la notificación una vez resuelta la petición.
 * @param {Function} handleClose Función para controlar los modals. Es requerida si `redirect` es false.
 */

export const useCloseModal = (
  isLoading,
  isSuccess,
  isError,
  error,
  successMessage,
  handleClose
) => {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      handleClose?.();

      successMessage &&
        enqueueSnackbar(successMessage, {
          variant: "success",
          autoHideDuration: 5000,
        });
    }

    if (isError) {
      showErrorMessages(error, enqueueSnackbar);
      handleClose?.();
    }
  }, [isLoading]);
};
