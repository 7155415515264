import { configureStore } from "@reduxjs/toolkit";

import {
  activosAPI,
  adqApi,
  areaApi,
  asignaturasApi,
  asignaturasPorProfesorApi,
  authApi,
  carrerasApi,
  carrerasPorGrupoApi,
  certificadoXMLApi,
  cicloApi,
  cicloInternoApi,
  cursosApi,
  entidadAPI,
  estadoApi,
  estadoPorEstudianteApi,
  estudianteApi,
  estudiantesPorCursoApi,
  estudiantesPorGrupoApi,
  evaluacionesApi,
  examenApi,
  fechaEvaluacionApi,
  firmaBoletaApi,
  firmaCursosApi,
  firmantesApi,
  grupoEscolarApi,
  gruposAPI,
  profesorApi,
  reporteRegulacionesApi,
  reporteTareaApi,
  tiposApi,
  unidadesApi,
  userApi,
  templatesApi,
  actasApi,
  tagsApi,
} from "services";

import {
  adminReducer,
  authReducer,
  calificacionesReducer,
  carreraReducer,
} from "../slices";

const principal = {
  auth: authReducer,
  admin: adminReducer,
  carrera: carreraReducer,
  calificaciones: calificacionesReducer,
};

const store = configureStore({
  reducer: {
    ...principal,
    [cursosApi.reducerPath]: cursosApi.reducer,
    [firmantesApi.reducerPath]: firmantesApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [adqApi.reducerPath]: adqApi.reducer,
    [unidadesApi.reducerPath]: unidadesApi.reducer,
    [estadoApi.reducerPath]: estadoApi.reducer,
    [tiposApi.reducerPath]: tiposApi.reducer,
    [areaApi.reducerPath]: areaApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [gruposAPI.reducerPath]: gruposAPI.reducer,
    [entidadAPI.reducerPath]: entidadAPI.reducer,
    [activosAPI.reducerPath]: activosAPI.reducer,
    [estudianteApi.reducerPath]: estudianteApi.reducer,
    [profesorApi.reducerPath]: profesorApi.reducer,
    [cicloApi.reducerPath]: cicloApi.reducer,
    [cicloInternoApi.reducerPath]: cicloInternoApi.reducer,
    [grupoEscolarApi.reducerPath]: grupoEscolarApi.reducer,
    [asignaturasApi.reducerPath]: asignaturasApi.reducer,
    [carrerasApi.reducerPath]: carrerasApi.reducer,
    [asignaturasPorProfesorApi.reducerPath]: asignaturasPorProfesorApi.reducer,
    [carrerasPorGrupoApi.reducerPath]: carrerasPorGrupoApi.reducer,
    [estadoPorEstudianteApi.reducerPath]: estadoPorEstudianteApi.reducer,
    [evaluacionesApi.reducerPath]: evaluacionesApi.reducer,
    [estudiantesPorGrupoApi.reducerPath]: estudiantesPorGrupoApi.reducer,
    [estudiantesPorCursoApi.reducerPath]: estudiantesPorCursoApi.reducer,
    [firmaBoletaApi.reducerPath]: firmaBoletaApi.reducer,
    [firmaCursosApi.reducerPath]: firmaCursosApi.reducer,
    [reporteRegulacionesApi.reducerPath]: reporteRegulacionesApi.reducer,
    [reporteTareaApi.reducerPath]: reporteTareaApi.reducer,
    [fechaEvaluacionApi.reducerPath]: fechaEvaluacionApi.reducer,
    [certificadoXMLApi.reducerPath]: certificadoXMLApi.reducer,
    [examenApi.reducerPath]: examenApi.reducer,
    [templatesApi.reducerPath]: templatesApi.reducer,
    [actasApi.reducerPath]: actasApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      unidadesApi.middleware,
      adqApi.middleware,
      estadoApi.middleware,
      tiposApi.middleware,
      areaApi.middleware,
      userApi.middleware,
      gruposAPI.middleware,
      entidadAPI.middleware,
      activosAPI.middleware,
      estudianteApi.middleware,
      profesorApi.middleware,
      cicloApi.middleware,
      grupoEscolarApi.middleware,
      asignaturasApi.middleware,
      carrerasApi.middleware,
      asignaturasPorProfesorApi.middleware,
      carrerasPorGrupoApi.middleware,
      estadoPorEstudianteApi.middleware,
      evaluacionesApi.middleware,
      estudiantesPorGrupoApi.middleware,
      cicloInternoApi.middleware,
      cursosApi.middleware,
      firmantesApi.middleware,
      estudiantesPorCursoApi.middleware,
      firmaBoletaApi.middleware,
      firmaCursosApi.middleware,
      reporteRegulacionesApi.middleware,
      reporteTareaApi.middleware,
      fechaEvaluacionApi.middleware,
      certificadoXMLApi.middleware,
      examenApi.middleware,
      templatesApi.middleware,
      actasApi.middleware,
      tagsApi.middleware,
    ]),
});

export default store;
