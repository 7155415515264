import { tagsApi } from "../view/tagsService";

export const getTags = tagsApi.injectEndpoints({
  endpoints: (builder) => ({
    getTags: builder.query({
      query: () => ({
        url: "/v1/api/tag/",
        method: "GET",
      }),
      providesTags: (result) =>
        Array.isArray(result)
          ? [
              ...result.map(({ id }) => ({
                type: "tags",
                id,
              })),
              { type: "tags", id: "LIST" },
            ]
          : [{ type: "tags", id: "LIST" }],
    }),
  }),
});

export const { useGetTagsQuery } = getTags;
